import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Reveal from "react-reveal/Reveal";

import { LinkWrap, Overlay } from "../styles/Work.styles";

import Footer from "../components/Footer";

import { Efect, Efect1, Efect2 } from "../styles/effect.styles";

const Works = ({ history }) => {
  const [toCase, setCase] = useState("");
  const [coord, setCoords] = useState();

  useEffect(() => {
    toCase &&
      setTimeout(() => {
        history.push(toCase);
      }, 600);
  }, [toCase, history]);

  const handleCaseSwap = (e, uri) =>
    e.x < coord + 15 && e.x > coord - 15 && setCase(uri);

  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />

      <div className="jumbotron head" />

      <Reveal effect="fadeInUp">
        <section className="container-fluid pb-0">
          <div className="row m-2-hor">
            <div className="col-md-12">
              <h1 className="heading mt-5">
                Encuentra lo que podemos hacer por ti.
              </h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className="container-fluid">
          <div className="row m-2-hor">
            <div className="col-md-3 slick slickproject p-3">
              <div className="slick-slide d-block">
                <div>
                  <div className="itm">
                    <LinkWrap active={toCase === "/proyectouno"}>
                      <Overlay
                        active={!!toCase}
                        onMouseDown={(e) => setCoords(e.nativeEvent.x)}
                        onMouseUp={(e) =>
                          handleCaseSwap(e.nativeEvent, "/proyectouno")
                        }
                      >
                        <div className="bg">
                          <img
                            src="./img/projects/img1.jpg"
                            className="img-fluid"
                            alt="Imageworks"
                          />
                        </div>
                        <div className="desc">
                          <div className="tag">Acabados</div>
                          <div className="name">Proyecto 1</div>
                        </div>
                        <div className="icon">
                          <span>Ver Proyecto</span>
                        </div>
                      </Overlay>
                    </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 slick slickproject p-3">
              <div className="slick-slide d-block">
                <div>
                  <div className="itm">
                    <LinkWrap active={toCase === "/proyectodos"}>
                      <Overlay
                        active={!!toCase}
                        onMouseDown={(e) => setCoords(e.nativeEvent.x)}
                        onMouseUp={(e) =>
                          handleCaseSwap(e.nativeEvent, "/proyectodos")
                        }
                      >
                        <div className="bg">
                          <img
                            src="./img/projects/img2.jpg"
                            className="img-fluid"
                            alt="Imageworks"
                          />
                        </div>
                        <div className="desc">
                          <div className="tag">Construcción</div>
                          <div className="name">Proyecto 2</div>
                        </div>
                        <div className="icon">
                          <span>Ver Proyecto</span>
                        </div>
                      </Overlay>
                    </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 slick slickproject p-3">
              <div className="slick-slide d-block">
                <div>
                  <div className="itm">
                    <LinkWrap active={toCase === "/proyectotres"}>
                      <Overlay
                        active={!!toCase}
                        onMouseDown={(e) => setCoords(e.nativeEvent.x)}
                        onMouseUp={(e) =>
                          handleCaseSwap(e.nativeEvent, "/proyectotres")
                        }
                      >
                        <div className="bg">
                          <img
                            src="./img/projects/img3.jpg"
                            className="img-fluid"
                            alt="Imageworks"
                          />
                        </div>
                        <div className="desc">
                          <div className="tag">Carpinteria</div>
                          <div className="name">Proyecto 3</div>
                        </div>
                        <div className="icon">
                          <span>Ver Proyecto</span>
                        </div>
                      </Overlay>
                    </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 slick slickproject p-3">
              <div className="slick-slide d-block">
                <div>
                  <div className="itm">
                    <LinkWrap active={toCase === "/proyectocuatro"}>
                      <Overlay
                        active={!!toCase}
                        onMouseDown={(e) => setCoords(e.nativeEvent.x)}
                        onMouseUp={(e) =>
                          handleCaseSwap(e.nativeEvent, "/proyectocuatro")
                        }
                      >
                        <div className="bg">
                          <img
                            src="./img/projects/img4.jpg"
                            className="img-fluid"
                            alt="Imageworks"
                          />
                        </div>
                        <div className="desc">
                          <div className="tag">Herrería</div>
                          <div className="name">Proyecto 4</div>
                        </div>
                        <div className="icon">
                          <span>Ver Proyecto</span>
                        </div>
                      </Overlay>
                    </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      <Footer />
    </div>
  );
};

export default withRouter(Works);
