import React from "react";

export default () => (
  <footer className="container-fluid black_more">
    <div className="bg-footer">
      <img src="./img/footer-img.png" alt="bg-footer" />
    </div>
    <div className="row m-2-hor">
      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">
            <h2>Nosotros</h2>
          </div>
          <div className="content">
            <p>
              VCA surge como una empresa que atiende a sus clientes con apego a
              sus necesidades de reparación, mantenimiento, decoración y
              redistribución de los espacios en donde viven y despliegan sus
              actividades diarias.
            </p>
          </div>
          <div
            className="link-call"
            onClick={() => window.open("mailto:contacto@vca.com", "_self")}
          >
            contacto@vca.com
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className="footer-col">
          <div className="heading">Sitio Web</div>
          <div className="content">
            <div className="link">Inicio</div>
            <div className="link">Somos VCA</div>
            <div className="link">Casos de Estudio</div>
            <div className="link">Contacto</div>
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className="footer-col">
          <div className="heading">Nuestras Políticas</div>
          <div className="content">
            <div className="link">Política de Privacidad</div>
            <div className="link">Términos y Condiciones</div>
            <div className="link">Térrminos de Uso</div>
            <div className="link">Términos de Servicio</div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">Nuestras redes</div>
          <div className="content">
            <p>Encuentra más información sobre nosotros en:</p>
            <div className="socialicon">
              <span className="shine"></span>
              <i className="fa fa-facebook-f"></i>
            </div>
            <div className="socialicon">
              <span className="shine"></span>
              <i className="fa fa-linkedin"></i>
            </div>
            <div className="socialicon">
              <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div>
            <div className="socialicon">
              <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="row m-2-hor">
        <div className="col-md-6">
          <div className="content">
            © Copyright 2022 VCA Todos los derechos reservados.
          </div>
        </div>
      </div>
    </div>
  </footer>
);
