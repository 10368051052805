import React from "react";

export default () => (
  <section className="container-fluid">
    <div className="row m-2-hor">
      <div className="col-md-6 pt-5">
        <div className="col-home">
          <div className="thumb">
            <img src="./img/home.jpg" className="img-fluid" alt="#" />
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="dflex-center">
          <div className="col-home mt-md-0 mt-5">
            <div className="heading">Somos VCA Compañia</div>
            <div className="content">
              VCA surge como una empresa que atiende a sus clientes con apego a
              sus necesidades de reparación, mantenimiento, decoración y
              redistribución de los espacios en donde viven y despliegan sus
              actividades diarias. Es decir, nosotros te ayudamos a que tus
              espacios en tu día a día sean más confortables, vívidos,
              armoniosos, pero sobre todo… más allegados a tus sueños! En VCA…
              Edificamos tus sueños!
            </div>
            <ul className="list-home">
              <li>
                {" "}
                Buscamos y localizamos los materiales idóneos para cada
                necesidad.
              </li>
              <li>
                Optimizamos esfuerzos y tiempos para que sean aprovechados de
                manera eficiente.
              </li>
              <li>Evitamos el mayor número de retrasos.</li>
              <li>Mejoramos cada idea durante su desarrollo y ejecucción.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);
