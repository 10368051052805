import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  Body,
  Page,
  LinkTag,
  SocialContainer,
  Overlaybg,
} from "../styles/Navigation.styles";
import { withRouter } from "react-router";
import NavButton from "./NavButton";

const NavigationMenu = ({ history, hasBackground, setBackground }) => {
  const [isOn, setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [linking, setLink] = useState("");

  useEffect(() => {
    !!linking &&
      setTimeout(() => {
        switch (linking) {
          case "Inicio":
            history.push("/");
            setState(false);
            setLink("");
            break;
          case "nosotros":
            history.push("/nosotros");
            setState(false);
            setLink("");
            break;
          case "servicios":
            history.push("/servicios");
            setState(false);
            setLink("");
            break;
          case "news":
            history.push("/news");
            setState(false);
            setLink("");
            break;
          case "contacto":
            history.push("/contacto");
            setState(false);
            setLink("");
            break;
          default:
            setLink("");
        }
        setBackground(false);
        window.scrollTo(0, 0);
      }, 0);
  }, [linking, history, setBackground]);

  useEffect(() => {
    shouldAnimate &&
      !isOn &&
      setTimeout(() => {
        setShouldAnimate(false);
      }, 0);
  }, [shouldAnimate, isOn]);

  const closeHandler = () => {
    setShouldAnimate(true);
    setState(!isOn);
  };

  const setLinkHandler = (text) => {
    setShouldAnimate(true);
    setLink(text);
  };

  useEffect(() => {
    const header = document.getElementById("header");

    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky + 0) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <header>
      <div id="header"></div>
      <div className="logo">
        <img src="./img/logowhite.png" className="img-fluid" alt="#" />
        <span className="callus">Llámanos: (+52) 322 134 7743</span>
      </div>
      <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
        <Overlaybg
          open={isOn}
          shouldAnimate={shouldAnimate}
          onClick={closeHandler}
        />
        <Container
          open={isOn}
          onClick={closeHandler}
          hasBackground={hasBackground}
        >
          <NavButton open={isOn} />
        </Container>
        <Body className="midwrpr" open={isOn} shouldAnimate={shouldAnimate}>
          <div className="conPage">
            <Page
              className="mainBtn"
              variant="Inicio"
              onClick={() => setLinkHandler("Inicio")}
            >
              <LinkTag>Inicio</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              variant="nosotros"
              onClick={() => setLinkHandler("nosotros")}
            >
              <LinkTag>Acerca de VCA</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              variant="servicios"
              onClick={() => setLinkHandler("servicios")}
            >
              <LinkTag>Casos de Estudio</LinkTag>
            </Page>

            <Page
              className="mainBtn"
              variant="contacto"
              onClick={() => setLinkHandler("contacto")}
            >
              <LinkTag>Contacto</LinkTag>
            </Page>
          </div>

          <div className="info">
            <span>(+52) 322 134 7743</span>
            <span className="link">contacto@vca.com</span>
            <span>P. Sherman, calle Wallaby, 42, Sydney</span>
          </div>
        </Body>
        <SocialContainer className="soc-icon" open={isOn}>
          <span>Síguenos en:</span>
          <span className="socicon">
            <i className="fa fa-facebook-f"></i>
          </span>
          <span className="socicon">
            <i className="fa fa-linkedin"></i>
          </span>
          <span className="socicon">
            <i className="fa fa-twitter"></i>
          </span>
          <span className="socicon">
            <i className="fa  fa-instagram"></i>
          </span>
        </SocialContainer>
      </Wrapper>
    </header>
  );
};

export default withRouter(NavigationMenu);
