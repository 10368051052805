import React from "react";

export default () => (
  <section className="container-fluid bggray py-4">
    <div className="row m-2-hor">
      <div className="col-lg-3 col-md-6">
        <div className="featurehome">
          <div className="img">
            <i className="fa fa-comments-o"></i>
          </div>
          <div className="text">Garantia de Calidad</div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6">
        <div className="featurehome">
          <div className="img">
            <i class="fa fa-list-alt"></i>
          </div>
          <div className="text">Trabajo Detallado</div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6">
        <div className="featurehome">
          <div className="img">
            <i className="fa fa-cogs"></i>
          </div>
          <div className="text">Garantía del Servicio</div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6">
        <div className="featurehome last">
          <div className="img">
            <i className="fa fa-line-chart"></i>
          </div>
          <div className="text">Satisfacción del Cliente</div>
        </div>
      </div>
    </div>
  </section>
);
