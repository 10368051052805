import React from "react";

export default () => (
  <section className="container-fluid pt-0" id="listcomplish">
    <div className="linebg">
      <img src="./img/linebg.png" className="img-fluid" alt="Imageteam" />
    </div>
    <div className="row m-2-hor">
      <div className="col-12 mb-5">
        <div className="heading">Nuestro Proceso de Trabajo</div>
      </div>
      <div className="col-12">
        <div className="listcomplish">
          <span className="year">1.º</span>
          <span className="content">El cliente nos selecciona.</span>
          <span className="detail">
            Captamos las necesidades del cliente detalladamente.
          </span>
        </div>
        <div className="listcomplish">
          <span className="year">2.º</span>
          <span className="content">
            {" "}
            Captamos las necesidades del cliente detalladamente.
          </span>
          <span className="detail">
            Creamos ideas a raíz de sus necesidades.
          </span>
        </div>
        <div className="listcomplish">
          <span className="year">3.º</span>
          <span className="content">
            solucionamos de manera directa cada inquietud.
          </span>
          <span className="detail">
            Dependiento del rubro de nuestro cliente.
          </span>
        </div>
        <div className="listcomplish">
          <span className="year">3.º</span>
          <span className="content">
            Creamos ideas a raíz de sus necesidades.
          </span>
          <span className="detail">
            Aprovechados los recursos de manera eficiente y sin demoras.
          </span>
        </div>
        <div className="listcomplish">
          <span className="year">4.º</span>
          <span className="content">
            Buscamos y localizamos los materiales idóneos para cada necesidad.
          </span>
          <span className="detail">La perfección es nuestro estandar.</span>
        </div>
        <div className="listcomplish">
          <span className="year">5.º</span>
          <span className="content">Optimizamos esfuerzos y tiempos.</span>
          <span className="detail">Trabajo hecho a la medida.</span>
        </div>
        <div className="listcomplish">
          <span className="year">6.º</span>
          <span className="content">
            Aprovechados los recursos de manera eficiente y sin demoras.
          </span>
          <span className="detail">Presupuestos precisos.</span>
        </div>
        <div className="listcomplish">
          <span className="year">7.º</span>
          <span className="content">
            Mejoramos cada idea durante su desarrollo y ejecución.
          </span>
          <span className="detail">Entregamos perfección.</span>
        </div>
      </div>
    </div>
  </section>
);
